//@import 'src/crediteo-theme.scss';∕

@import 'variables';

html, body {
  height: 100%;
}

body {
  //position: absolute;
  //top:0;
  //width: 100%;
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.no-scroll {
  overflow: hidden !important;
}

.bottom-zero {
  position: absolute;
  bottom: 0;
}

.clickable {
  cursor: pointer !important;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

.container {
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.main {
  height: calc(100% - 96px); // On retire les 96px de la navbar
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
  display: flex;
  overflow: auto;
  flex-direction: column;
}

.message-aide {
  padding:15px;margin-bottom:20px;border:1px solid #bce8f1;border-radius:4px;
  color:#31708f;background-color:#d9edf7;
}

.fit {
  max-width: 1280px;
  margin: 0 auto;
  // width: 100%;
  width: calc(100% - 50px);
}

.hide-espace-support {
  display: none
;
}

.mat-drawer-side {
  border: none;
}

.side-nav {
  height: calc(100% - 96px);

  .mat-drawer {
    //height: 110px;
    width: 210px;
    background: #fafafa;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0 0 0 16px;
    color: white;

    .nav-button {
      display: block;
      height: 75px;
      text-align: left;
      width: 100%;

      &.active-tab {
        background-color: rgba(0, 0, 0, 0.2);
      }
    }
  }

}

.filtres {
  //margin-top: 16px;
  padding: 0 24px;
  background-color: white;

  & form {
    display: inline-block;
  }

  & mat-toolbar {
    position: absolute;
    left: 0;
    top: 0;
    padding: 16px 32px;

    @media (max-width: 600px) {
      padding: 16px 0;
    }
  }
}

.mainDiv {
  & .mat-card {
    max-width: 1280px;
    margin: 0 auto;
  }
}

.mat-drawer {
  background-color: #fafafa;
}

.active-tab {
  font-weight: bold;
}

.mat-column-actions {
  flex: 0 10%;
}

.btn-custom {
  width: 120px !important;
  height: 38px !important;
  flex: none !important;
  max-width: none !important;
  margin: 12px 12px 12px 0 !important;
}

.btn-custom-xl {
  width: 200px !important;
  height: 38px !important;
  flex: none !important;
  max-width: none !important;
  margin: 12px 12px 12px 0 !important;
}

.btn-custom.mat-stroked-button {
  border-color: white;
}

/* Boutons */
// FLAT
.btn-flat-positive {
  background-color: $positive-color;
  color: white;
}
.btn-flat-negative {
  background-color: $negative-color;
  color: white;
}
.btn-flat-warning {
  background-color: $warning-color;
  color: white;
}

// STROKED
.btn-stroked-positive {
  border-color: $positive-color !important;;
  color: $positive-color
}
.btn-stroked-negative {
  border-color: $negative-color !important;;
  color: $negative-color
}
.btn-stroked-warning {
  border-color: $warning-color !important;;
  color: $warning-color
}
/* Boutons */



.tableRow {
  cursor: pointer;
}

.tableRow:hover {
  background: darken(white, 5);
}

.title-page {
  text-align: center;
}

cre-account-menu {
  text-align: right;
}


.mat-form-field-checkbox {
  padding-top: 25px !important;
}

.display-block {
  display: block;
}

.display-inline-block {
  display: inline-block;
}

/* INPUT */

//.mat-form-field-wrapper {
//  margin: 0;
//  padding-bottom: 0;
//}

.mat-form-field-disabled .mat-form-field-prefix {
  color: rgba(0,0,0,.38);
}

/* SNACKBARS */

.red-snackbar {
  background-color: #DF1606;
  color: white;
}

.green-snackbar {
  background-color: #397C0D;
  color: white;
}

/* COMPONENT DATE PICKER REQUIREMENTS */

.mat-form-field {
  padding: 8px;
  min-width: 210px;

  @media (max-width: 600px) {
    min-width: 0;
  }
}

mat-dialog-content {
  padding-top: 12px !important;
}

.mat-form-field mat-form-field {
  padding: 0;
  min-width: 0;
}


/* SCROLLABLE DIRECTIVE REQUIREMENTS */

tbody.scrollable, mat-grid-list.scrollable, .scroll-content.scrollable {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 1;
  flex-grow: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  flex-basis: 100%;
}

.mat-form-field-suffix {
  display: flex;
}

.fit {
  h4 {
    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    font-weight: bold;
    margin-bottom: 0;
  }
}

mat-paginator .mat-form-field {
  min-width: auto;
}

// Permet de corriger l'alignement vertical des checkbox
.fix-align-checkbox {
  padding-top: 25px;
  padding-left: 8px;
}

*[disabled] {
  cursor: not-allowed;
}

.mat-paginator {
  min-width: 415px;
}

.crediteo-to-premista {
  filter: hue-rotate(-33deg);
}

.siren-prefixed.mat-form-field-should-float .mat-form-field-prefix+.mat-form-field-infix .mat-form-field-label-wrapper
{
  left: -75px;
}

.emptyListMsg {
  padding: 12px;
  font-size: larger;
  font-weight: bold;
}

input.force-uppercase {
  text-transform: uppercase;
}

mat-chip {
  user-select: none;
  mat-icon {
    cursor: pointer !important;
  }
}
